import React from 'react'
import { Link } from '~components'
import styled from 'styled-components/macro'
import { Box } from '~styled'

export const HeaderButtonWrapper = styled(Box)`
    .btn{
        min-width:auto;
        height: 42px;
        border-radius: 3px;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        display:flex;
        align-items:center;
        justify-content:center;
        padding-left:15px;
        padding-right:15px;
        letter-spacing: -0.4px;
        margin-left:10px;
        color:#5338fc;
        @media (max-width:1050px) {
            font-size: 13px; 
          }
        &-2{
            border-radius:500px;
            color:#fff;
            min-width: 150px;
            height: 42px;
            background-color: #5338fc;
            border-color: #5338fc;
            height: 42px;
            &:hover{
                background-color: #4730D6;
                box-shadow: 0 0px 0px rgba(241, 139, 98, 0.3);
            }
        }
    }
`
const HeaderButton = ({btnOneLink,btnTwoLink,btnOneText,btnTwoText,...rest})=>{
    return(
        <HeaderButtonWrapper {...rest}>
            <Link target="_blank" className="btn btn-1" to={btnOneLink ? btnOneLink : "//wlapp.we-connect.io/login"}>
                {btnOneText}
            </Link>
            <Link target="_blank" className="btn btn-2" to={btnTwoLink ? btnTwoLink : "//wlapp.we-connect.io/register"}>
                {btnTwoText}
            </Link>
        </HeaderButtonWrapper>
    )
}

export default HeaderButton;