import React from 'react'
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/whitelabel/Build a predictable.svg";

export default function FeatureBox({backgroundColor,colClasses,...rest}){

return(
<Content {...rest}>
<Container>

    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Build a predictable monthly recurring revenue"/> </Content.Title>

          <Content.Text> <SuperTag value="Gone are the days of a fluctuating monthly income. We-Connect increases the lifetime value of your customers – with a typical customer paying for 8 to 9 months of our software. Say goodbye to one-time projects and occasional customers. Instead, say hello to revenue you can rely on."/> </Content.Text>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
    
    
  </Container>
  </Content>
)}