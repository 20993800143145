import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
//import ServiceData  from "~data/startup/service"
import Content from './style'
import image1 from "~assets/image/whitelabel/Plug-&-Play.svg";
import image2 from "~assets/image/whitelabel/Robust-&-Reliable-Platform.svg";
import image3 from "~assets/image/whitelabel/Stunning-Design-and-UI.svg";
import  ServicesCard from "../../../affiliates/ServicesOne/Components/Card"
import { SuperTag } from '~components';
import { Link } from "gatsby";

export default function SecTwo({backgroundColor,colClasses,...rest}){
return(
<Content {...rest} backgroundColor="#F3F8FE">
  <Container>

    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Content.TextBlock>
        <Content.Title as="h2"> <SuperTag value="The Best LinkedIn Lead Generation Tool for Agencies"/> </Content.Title>

        <Content.Text> <SuperTag value="Your agency is expanding. Ensure every lead generation campaign succeeds using We-Connect, the most advanced LinkedIn lead generation software on the market."/> </Content.Text>
        
      </Content.TextBlock>
    </Row>
    
    <Row className="justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-duration={500} data-aos-delay={500} data-aos-once="true">
      {/* Single Services */}
      
              <Col xs="h2" className="col-xl-3 col-lg-4 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Plug & Play Integration"
                  text="Easy & quick set up. No technical expertise required. Custom domain, Branding, SMTP, Stripe, and Intercom integration available."
                  image={image1}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-4 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Robust & Reliable Platform"
                  text="Trusted by leading B2B agencies. Find out what makes We-Connect the most advanced cloud-based social selling platform."
                  image={image2}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-4 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Stunning Design and UI"
                  text="Easy-to-use workflows in an interactive dashboard. Easily manage hundreds of customers and campaigns with robust admin controls."
                  image={image3}
                  iconBackground="#F3F4F6"
                />
              </Col>
              
            
      {/*/ .Single Services */}
    </Row>

  </Container>
</Content>

)
}