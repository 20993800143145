import React from 'react'
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/whitelabel/Generate leads on autopilot.svg";

export default function Features({backgroundColor,colClasses,...rest}){

return(
<Content {...rest}>
<Container>

    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Content.HeadingBlock>
        <Content.Heading as="h2"> <SuperTag value="Build long-lasting customer relationships like never before"/> </Content.Heading>

        <Content.Description> <SuperTag value="There are thousands of customers out there – and each wants something a little different. We–Connect gives you the flexibility to serve all types of customers so you can continue to grow your agency."/> </Content.Description>
        
      </Content.HeadingBlock>
    </Row>

    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Generate leads on autopilot"/> </Content.Title>

          <Content.Text> <SuperTag value="Using We-Connect, search over 500 million profiles, target your ICP, set up drip campaigns, and get replies in your inbox daily. And, the best part? We automate everything so you can focus your attention on other tasks."/> </Content.Text>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
    
    
  </Container>
  </Content>
)}