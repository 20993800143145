import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/HeaderWL'
import { Helmet } from "react-helmet";
import Hero from "~sections/project/Whitelabel/Hero/Hero"
import SecTwo from "~sections/project/Whitelabel/SectionTwo/SecTwo"
import Features from "~sections/project/Whitelabel/Features/Features"
import FeatureBox from "~sections/project/Whitelabel/FeatureBox/FeatureBox"
import FeatureBoxTwo from "~sections/project/Whitelabel/FeatureBoxTwo/FeatureBoxTwo"
import CtaWL from "~sections/marketing/CtaWL/CtaWL"
import FooterWL from "~sections/marketing/FooterWL/FooterWL"

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="Apply now"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function Whitelabel() {
  return (
    <>
    <Helmet>
        <title>The Best LinkedIn Lead Generation Tool for Agencies</title>
        <meta name="description" content="Expand your agency with We-Connect, the safest automation software around. Build a predictable monthly recurring revenue white generating leads on autopilot."/>
        <meta property="og:title" content="We-Connect | The Best LinkedIn Lead Generation Tool for Agencies "/>
        <meta property="og:description" content="Expand your agency with We-Connect, the safest automation software around. Build a predictable monthly recurring revenue white generating leads on autopilot. "/>
        <meta property="og:image" content="https://we-connect.io/we-connect_white-label-page.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/whitelabel"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect | The Best LinkedIn Lead Generation Tool for Agencies"/>
        <meta property="twitter:description" content="Expand your agency with We-Connect, the safest automation software around. Build a predictable monthly recurring revenue white generating leads on autopilot. "/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect_white-label-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
        
      <Hero />
      <SecTwo />
      <Features />
      <FeatureBox />
      <FeatureBoxTwo />
      <CtaWL />
      <FooterWL/>
    </PageWrapper>
    </>
  )
}
