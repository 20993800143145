import React from 'react'
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/whitelabel/Grow your B2B Lead Generation Agency.svg";
import { Link } from "gatsby";

export default function Hero({backgroundColor}){

return(
<Content>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Transform your agency into a SAAS based revenue-generating machine."/> </Content.Title>

          <Content.Text> <SuperTag value="Showcase our software under your domain. Draw in customers with a monthly recurring subscription packed with value and continue to grow your agency with over a 10X margin."/> </Content.Text>
          <Link to="//wlapp.we-connect.io/register" target="_blank"><Content.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} rounded="true" sizeX="180px" sizeY="56px">Apply now</Content.Button></Link>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}