import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
//import {Form} from '~components';
//import { Images } from '~data';
import Cta from '../Cta/style';
const CtaWL = () => {
return(
<>
<Cta className="cta-section cta-section--l1 dark-mode-texts" backgroundColor="#f3f8fe">
  
  <Container>
    <Row className="justify-content-center">
      <Col className="col-lg-7 col-md-8 text-center">
        <Cta.Box>
          <Cta.Title as="h2" fontColor="#25373f">It’s easy to get started.</Cta.Title>
          {/* Newsletter */}
          <Cta.Newsletter>
            <form action="//wlapp.we-connect.io/register"  target="_blank" form-id="kt_login_signup_form"  novalidate>
            <Cta.InputBox>
            <input placeholder="@ Enter your email" type="email" name="email" required ps-email-field-id="eab3eabe-8d1d-4025-8361-8694d9c7b13b" className="form-control"/>
                <Cta.Button  className=" btn btn-primary text-white" type="submit" ps-submit-button-id="182620e1-2a48-45ce-b154-8b4cc46913a1">Apply now</Cta.Button>
              </Cta.InputBox>
            </form>
          </Cta.Newsletter>
          {/*/ .Newsletter */}
        </Cta.Box>
      </Col>
    </Row>
  </Container>
</Cta>

</>
)
}
 
export default CtaWL