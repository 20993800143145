import React from 'react'
import Content from '../Hero/style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/whitelabel/Easily prove value.svg";
import { Link } from "gatsby";

export default function FeatureBox({backgroundColor,colClasses,...rest}){

return(
<Content {...rest}>
<Container>

    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Easily prove value to your clients"/> </Content.Title>

          <Content.Text> <SuperTag value="Using our all-in-one dashboard, your customers can clearly see the ROI of your agency with regularly updated stats. Empower them to download detailed reports and track the progress of specific campaigns that highlight your value. Seamlessly integrate We-Connect with CRM systems and other apps via Zapier or API."/> </Content.Text>
          <Link to="//wlapp.we-connect.io/register" target="_blank"><Content.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} rounded="true" sizeX="180px" sizeY="56px">Apply now</Content.Button></Link>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>

    
  </Container>
  </Content>
)}