import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';
import {transparentize} from "polished"

const propToColor = (arg = "#442cd6" ) => transparentize(0.70, arg);
const Content = styled(Box)`
    position: relative;
`
Content.Inner = styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;
    
    @media (min-width: 768px){
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: 992px){
        padding-top: 132px;
        padding-bottom: 135px;
    }

`
Content.TextBlock=styled(Box)`

`
Content.HeadingBlock=styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width: 768px){
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: 992px){
        padding-top: 132px;
        padding-bottom: 135px;
    }
`
Content.Block = styled(Box)`
padding-top: 80px;
@media (min-width: 768px) {
    padding-top: 0;
}

`
Content.Title = styled(Heading)`
font-size: 30px;
font-weight: 500;
letter-spacing: -2px;
line-height: 1.20689;
margin-bottom: 32px;

@media (min-width: 480px) {
    font-size: 37px;
}

@media (min-width: 768px) {
    font-size: 40px;
}

@media (min-width: 992px){
    font-size: 45px;
}
@media (min-width: 1400px){
    font-size: 52px;
}
`

Content.Heading = styled(Heading)`
font-size: 34px;
font-weight: 500;
text-align: center;
letter-spacing: -2px;
line-height: 1.20689;
margin-bottom: 32px;

@media (min-width: 480px) {
    font-size: 38px;
}

@media (min-width: 768px) {
    font-size: 48px;
}

@media (min-width: 992px){
    font-size: 34px;
}
`
Content.Text = styled(Paragraph)`
font-size: 18px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 12px;
`
Content.Description = styled(Paragraph)`
font-size: 18px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 12px;
text-align: center;
`

Content.Image = styled(Box)`
position: relative;
margin-bottom: 40px;

@media (min-width: 992px){
    margin-left: 45px;
    margin-bottom: 0;
}

`
Content.SectionShape=styled(Box)`
    position: absolute;
    left: -3.8%;
    top: 12%;
    z-index: 0;
    width: 9%;
`
Content.ContentShape = styled(Box)`
    width: 38%;
    position: absolute;
    right: 13%;
    bottom: 0;
    z-index: -1;

`
Content.Button = styled(Button)`
    transition:.4s;
    margin-bottom: 40px;
    margin-top: 40px;
    border-color:${props => props.backgroundColor? props.backgroundColor: "#5034fc" };
    background:${props => props.backgroundColor? props.backgroundColor: "#442cd6" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.backgroundColor)};
  &:hover{
    transform: translateY(-5px);
    border-color:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    background:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.hoverBackgroundColor)};
  }
`

Content.Box = styled(Box)``


export default Content;